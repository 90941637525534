import React from 'react';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import "react-vertical-timeline-component/style.min.css";
import SchoolIcon from "@material-ui/icons/School";
import WorkIcon from "@material-ui/icons/Work";

import '../styles/experience.css';

function Experience() {
  return (
    <div className="experience">
      <h3> Experience</h3>
      <VerticalTimeline lineColor="#3E497A">
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          date="Dec 2016  - Apr 2022"
          iconStyle={{ background: "#e9d35b", color: "#fff" }}
          icon={<WorkIcon />}
        >
          <h3 className="vertical-timeline-element-title">
            Front End Engineer - Pronto Japanese and Korean Restaurant
          </h3>
          <h4 className="vertical-timeline-element-subtitle">
            Regina, Saskatchewan
          </h4>
          <p>Maintained and designed websites as per request.</p>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          date="Feb 2019  - Apr 2019"
          iconStyle={{ background: "#e9d35b", color: "#fff" }}
          icon={<WorkIcon />}
        >
          <h3 className="vertical-timeline-element-title">
            ReactJS Developer - Alttra Solutions
          </h3>
          <h4 className="vertical-timeline-element-subtitle">
            Regina, Saskatchewan
          </h4>
          <p>Fixed UI errors and added features on the user page.</p>
        </VerticalTimelineElement>        

        <VerticalTimelineElement 
          className="vertical-timeline-element--education"
          date="May 2017 - Jun 2022"
          iconStyle={{background: "#3E497A", color: "#FFFFFF"}}
          icon={<SchoolIcon /> }
        >
          <h3 className="vertical-timeline-element-title">
            University of Regina
          </h3>
          <h4 className="vertical-timeline-element-subtitle">
            Regina, Saskatchewan
          </h4>

          <p>Bachelor's Degree in Software Systems Engineering</p>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          date="Dec 2016 - Feb 2017"
          iconStyle={{ background: "#e9d35b", color: "#fff" }}
          icon={<WorkIcon />}
        >
          <h3 className="vertical-timeline-element-title">
            Web Developer - Hybrid Renovations and Restoration
          </h3>
          <h4 className="vertical-timeline-element-subtitle">
            Regina, Saskatchewan
          </h4>
          <p>Troubleshoot issues on the presentation layer of the company website basis for the requests and helped search engine optimization (SEO).</p>
        </VerticalTimelineElement> 
      </VerticalTimeline>     
      
    </div>
  );
}

export default Experience
