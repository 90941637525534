import React from 'react';
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import GithubIcon from "@material-ui/icons/GitHub";
import TwitterIcon from "@material-ui/icons/Twitter";

import "../styles/home.css";

function Home() {
  return (
    <div className="home">
      <div className="about"> 
        <h2> Hi, my name is Brian</h2>
        <img src="/images/brian.png" alt="profile" />
        <div className="prompt">
          <p> Graduate with a Bachelor's degree in Software Systems Engineering with interests in software development. Highly motivated to leverage a relevant education, extensive prior work experience, and a solid work ethic to deliver practical solutions for software development.  </p>
          <a href="https://www.linkedin.com/in/jiwoun-kim-595624124/" target="_blank" rel="noreferrer"><LinkedInIcon /></a>
          <a href="https://github.com/brianj-kim?tab=repositories" target="_blank" rel="noreferrer"><GithubIcon /></a>
          <a href="https://twitter.com/cream_soupe" target="_blank" rel="noreferrer"><TwitterIcon /></a>
        </div>
      </div>
      <div className="skills"> 
        <h3> Skills </h3>
        <ol className="list">
          <li className="item">
            <h4> Front-End</h4>
            <span> ReactJS, Redux, Redux toolkit, React Hooks, AngularJS(13+), MaterialUI, Tailwind CSS, HTML, Bootstrap (Customizable)</span>
          </li>

          <li className="item">
            <h4> Back-End</h4>
            <span> Spring Boot, NodeJS, ExpressJS, Redis, GraphQL, MySQL, PostgreSQL, MongoDB, Firestore, AWS S3, Cassandra (Datastax)</span>
          </li>

          <li className="item">
            <h4> Languages</h4>
            <span> JavaScript, TypeScript, Java, Python, C++, C, C# (.Net Core 6+), PHP, Go</span>
          </li>

          <li className="item">
            <h4> Tools</h4>
            <span>Git, Github, Docker, Docker Compose, Postman, IntelliJ, Visual Studio Code, Visual Studio for Mac, macOS, Linux (Oracle Linux 8+), Windows, Windows Server</span>
          </li>

        </ol>
      </div>
    </div>
  );
}

export default Home
