import React from 'react';
import ProjectItem from '../components/ProjectItem';
import { ProjectList } from '../helpers/ProjectList'

import '../styles/projects.css';


function Projects() {
  return (
    <div className="projects">
      <h3> Projects</h3>
      <div className="projectList">
        {ProjectList.map((project, idx) => {
          return <ProjectItem key={idx} id={idx} name={project.name} image={project.image} desc={project.desc} link={project.link}/>
        })}
        
      </div>
    </div>
  )
}

export default Projects
