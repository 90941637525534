import React, { useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import '../styles/navbar.css';
import ReorderIcon from '@material-ui/icons/Reorder';

function Navbar() {
  const [expandNavbar, setExpandNavbar] = useState(false);

  const location = useLocation();

  useEffect(() => {
    setExpandNavbar(false);
  }, [location])

  return (
    <div className="navbar" id={expandNavbar ? "open" : "close"}>
        <div className="toggleButton">
            <button onClick={() => {setExpandNavbar((prev) => !prev);}}>
              <ReorderIcon />
            </button>
        </div>
        <div className="links">
            <NavLink to="/" className="link" activeClassName="active"> <li>Home</li> </NavLink>
            <NavLink to="/projects" className="link" activeClassName="active"> <li>Projects</li> </NavLink>
            <NavLink to="/experience" className="link" activeClassName="active"> <li>Experience</li> </NavLink>
        </div>
      
    </div>
  )
}

export default Navbar
