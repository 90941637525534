
export const ProjectList = [
  {
    name: "Recipe Admin",
    image: "/images/recipe-manager.jpg",
    desc: "Fullstack app for managing Categories, Menus, and Recipe for the cost management",
    skills: "Spring Boot, PostgreSQL, ReactJS, React Hooks, Tailwind CSS: Backend stack is deployed on Heroku",
    link: "https://github.com/brianj-kim/RecipeManagerReactJS"
  },
  {
    name: "New Website for Pronto",
    image: "/images/newProntoApril2022.jpg",
    desc: "Pronto Japanese and Korean Restaurant's new website updated on April 2022",
    skills: "ReactJS, JSON, Tailwind CSS, HTML",
    link: "https://github.com/brianj-kim/pronto-updated-April22"
  },
  {
    name: "Youniversity",
    image: "/images/youniversity.jpg",
    desc: "A class project from the university. Collaborated with a team member to build a single page responsive web application, which recommends YouTube DIY content and evaluates the content link with a like button and comments.",
    skills: "ReactJS, Tailwind CSS, FireStore (NoSQL database), HTML",
    link: "https://github.com/brianj-kim/ENSE-405-Project"
  },
  {
    name: "SoundByte",
    image: "/images/soundbyte.png",
    desc: "University of Regina Software Systems Engneering Capstone Project, recommending music to mix through machine learning & possible sample generation.",
    skills: "NodeJS, ElectronJS, JavaScript, TypeScript, Python, HTML, CSS",
    link: "https://github.com/brianj-kim/SoundByte"
  },
  
];