import React from 'react';
import { useParams } from 'react-router-dom';
import { ProjectList } from '../helpers/ProjectList';
import GitHubIcon from '@material-ui/icons/GitHub';

import '../styles/projectDisplay.css';

function ProjectDisplay() {
    const { id } = useParams();
    const project = ProjectList[id];
  return (
    <div className="project">
        <h1>{project.name}</h1>
        <img src={project.image} alt={project.name}/>
        <p className="desc">{project.desc}</p>
        <p>
            <b>Skills</b>: {project.skills}
        </p>
        { (project.link !== "") ? <a href={project.link} target="_blank" rel="noreferrer"><GitHubIcon /></a> : null }
    </div>
  )
}

export default ProjectDisplay;
