import React from 'react';

import TwitterIcon from "@material-ui/icons/Twitter";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import GithubIcon from "@material-ui/icons/GitHub";
import "../styles/footer.css";

function Footer() {
  return (
    <div className="footer">
        <div className="socialMedia">
          <a href="https://twitter.com/cream_soupe" target="_blank" rel="noreferrer"><TwitterIcon /></a>
          <a href="https://www.linkedin.com/in/jiwoun-kim-595624124/" target="_blank" rel="noreferrer"><LinkedInIcon /></a>
          <a href="https://github.com/brianj-kim?tab=repositories" target="_blank" rel="noreferrer"><GithubIcon /></a>
          
        </div>
        <p>&copy; 2022 brianjkim.dev</p>      
    </div>
  )
}

export default Footer
